import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './About.css';

function About() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentDetailIndex, setCurrentDetailIndex] = useState(0);

  const cards = [
    {
      title: 'Promoting Talent at All Age Groups',
      imageUrl: 'images/talent.png',
      details: [
        'Recognize and support unique skills across all life stages.',
        'Provide platforms and resources for talent to excel.',
      ],
    },
    {
      title: 'Counseling Services',
      imageUrl: '/images/counseling1.png',
      details: [
        'Educational Counseling for Parents and Children.',
        'Interview Preparation - Training for job interviews.',
        'Marital and Divorce Counseling.',
        'Career and Skills Training in Rural Areas.',
        'Higher Education Counseling after 12th grade.',
        'CV Preparation Guidance.',
      ],
    },
    {
      title: 'Events and Community Programs',
      imageUrl: '/images/commevt.png',
      details: [
        'Education and Job Fairs - Connecting individuals with employers.',
        'Community Support Events - Awareness programs for safety.',
      ],
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCardClick = (card, index) => {
    if (selectedIndex === index) {
      setSelectedCard(null);
      setSelectedIndex(null);
      setCurrentDetailIndex(0);
    } else {
      setSelectedCard(card);
      setSelectedIndex(index);
      setCurrentDetailIndex(0);
    }
  };

  const handleCloseDetails = () => {
    setSelectedCard(null);
    setSelectedIndex(null);
    setCurrentDetailIndex(0);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDetail(),
    onSwipedRight: () => handlePreviousDetail(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  });

  const handleNextDetail = () => {
    if (currentDetailIndex < selectedCard.details.length - 1) {
      setCurrentDetailIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousDetail = () => {
    if (currentDetailIndex > 0) {
      setCurrentDetailIndex((prevIndex) => prevIndex - 1);
    }
  };

  const renderDetailsContainer = () => {
    if (!selectedCard) return null;

    return (
      <div className="details-container" {...handlers}>
        <h3>{selectedCard.title}</h3>
        <div className="swipeable-content">
          <button
            className="arrow-button"
            onClick={handlePreviousDetail}
            disabled={currentDetailIndex === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <p>{selectedCard.details[currentDetailIndex]}</p>
          <button
            className="arrow-button"
            onClick={handleNextDetail}
            disabled={currentDetailIndex === selectedCard.details.length - 1}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
        <button className="close-button" onClick={handleCloseDetails}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
        <div className="dot-indicators">
          {selectedCard.details.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentDetailIndex ? 'active' : ''}`}
              onClick={() => setCurrentDetailIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="about-container">
      <h1 className="servHeadings">Our Services</h1>
      <p className="servP">
        Peter's Foundation offers a holistic approach to empowering individuals, from talent
        development to counseling and community programs.
      </p>

      <div className="about-grid">
        {cards.map((card, index) => (
          <React.Fragment key={index}>
            <div
              className="about-card"
              onClick={() => handleCardClick(card, index)}
              style={{ backgroundImage: `url(${card.imageUrl})` }}
            >
              <div className="about-card-overlay">
                <h2>{card.title}</h2>
              </div>
            </div>

            {/* Mobile view: Show details container right after each card */}
            {isMobile && selectedIndex === index && renderDetailsContainer()}
          </React.Fragment>
        ))}
      </div>

      {/* Desktop view: Show details container after the entire grid */}
      {!isMobile && renderDetailsContainer()}
    </div>
  );
}

export default About;
