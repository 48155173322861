// src/components/Events.js
import React from 'react';
import './Event.css';
import { Button } from './Button';

function Events() { 
  return (
    <div className="events-container">
      <h1 className='eventsh1'>Events</h1>
      <h1 className='adh'>Upcoming Event: <strong>"Annual Day Hungama" </strong>- Our YouTube Video Competition</h1>
      
      <div className="events-grid">
        {/* Brochure Image */}
        <div className="brochure">
          <img src="images/Books.png" alt="Vismay Brochure" className="brochure-image" />
        </div>
        
        {/* Event Details */}
        <div className="event-details">
          <p>We’re excited to announce our YouTube Video Competition!</p>
          
          <p>Peter's Foundation brings a unique opportunity for schools and colleges to showcase their students' talents and gain visibility across a wide audience.</p>
          
          <h2>What's in it for you?</h2>
          <ul>
            <li>Increased online presence and community engagement</li>
            <li>Opportunity to showcase your students’ talents and achievements</li>
            <li>Recognition and visibility through our YouTube platform</li>
          </ul>
          <p>Not to mention, the videos with the most views will win exciting prizes!</p>
          
          <h2>How to Participate</h2>
          <p>To participate, simply share a video of your most interesting and engaging annual day program from previous years.</p>
          
          <div className="highlight">
            <p><strong>Share Your Annual Function Highlights!</strong></p>
            <p>Let’s celebrate the vibrant culture and achievements of your institution together!</p>
          </div>

          <h2>Rules & Regulations:</h2>
          <ul>
            <li>The institution can select the best video from their previous years' annual day functions</li>
            <li>Number of submissions:
              <ul>
                <li>Schools: 2 (1 from Primary and 1 from Secondary level)</li>
                <li>Colleges: 1</li>
              </ul>
            </li>
            <li>Edited videos are allowed</li>
            <li>Once uploaded, the videos cannot be changed</li>
          </ul>

          <p className="footer-note">So, what are you waiting for? Join us in making a memorable event!</p>
          
          {/* Register Button */}
          <Button
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            onClick={() => window.open('https://forms.gle/kvsmJwrtBNQRNoMs7', '_blank')}
          >
            Register Now
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Events;
