import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';



const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleInput = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src="images/PFlogo.jpeg" alt="Logo" width='80px' onClick={closeMobileMenu}/>
                </Link>
                <div className="menu-icon" onClick={handleInput}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/home" className="nav-links" onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                            Services
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/events" className="nav-links" onClick={closeMobileMenu}>
                            Events
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/gallery" className="nav-links" onClick={closeMobileMenu}>
                            Gallery
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
                            Contact
                        </Link>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
