import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Event from './components/Event';
import Gallery from './components/Gallery';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={ <About/>} />
        <Route path="/events" element={ <Event/>} />
        <Route path="/gallery" element={ <Gallery/>} />       
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
