import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
  // Scroll function to the About Us section
  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero-container">
      <video src="images/main.mp4" autoPlay loop muted />
      <h1 className="playwrite-gb-s-hero-h1">Peter's Foundation</h1>
      <div className="hero-btns">
        {/* About Us Button with scroll functionality */}
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          onClick={scrollToAbout}
        >
          ABOUT US
        </Button>

        {/* Events Button linking to the Events page */}
        <Link to="/events">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
          >
            EVENTS <i className="far fa-play-circle" />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
