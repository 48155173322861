import React from 'react';
import './AboutUs.css';

function About() {
  return (
    <div id="about" className="about-container">
      <h1 className="playwrite-gb-s-about-h1">About Us</h1>
      <div className="about-content">
        <h2 className="mission-vision-heading">Our Mission</h2>
        <p>At Peter's Foundation Initiative, we believe in nurturing potential and providing the tools necessary for individuals to thrive. Our mission is to support people across all age groups through educational, vocational, and personal counseling services. We are committed to helping people realize their true potential by providing opportunities, skills training, and guidance for a prosperous life.</p>

        <h2 className="mission-vision-heading">Our Vision</h2>
        <p>We envision a world where every individual has access to resources and opportunities to achieve their fullest potential, contributing positively to society. Through our holistic approach, we strive to be a catalyst for personal and community growth.</p>
      </div>
    </div>
  );
}

export default About;
