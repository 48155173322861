import React from 'react';
import './CardLinks.css';
import { Link } from 'react-router-dom';

const LinkCards = () => {
  const cards = [
    {
      title: "Annual Day Hungama",
      imageUrl: "/images/annualday.png",
      description: "Check  Out Our Latest Event",
      link: "/events",
    },
    {
      title: "Services",
      imageUrl: "/images/handshake.webp",
      description: "Explore the services we offer to our community.",
      link: "/about",
    },
    {
      title: "Gallery",
      imageUrl: "/images/1.jpeg",
      description: "Explore our Past Events and Achievements.",
      link: "/gallery",
    },
  ];

  return (
    <div className="link-cards-container">
      {cards.map((card, index) => (
        <Link to={card.link} key={index} className="link-card">
          <div
            className="card-image"
            style={{ backgroundImage: `url(${card.imageUrl})` }}
          />
          <div className="card-content">
            <h2>{card.title}</h2>
            <p>{card.description}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default LinkCards;
