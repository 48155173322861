import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-container">
      {/* Navigation Links */}
      <div className="footer-links">
        <div className="footer-column">
          <Link to="/home">Home</Link>
          <Link to="/about">Services</Link>
          <Link to="/events">Events</Link>
          <Link to="/gallery">Gallery</Link>
        </div>
        <div className="footer-column">
          <p>
         <Link to="https://maps.app.goo.gl/5JgTXaZkkCbkLnG27" target="_blank">
          KL Tower <br></br>
          Mala Vazhi <br></br>
          Junction Above KSFE <br></br>
          Aloor Thrissur,680683</Link>
          </p>
        </div>
        <div className="footer-column">
          <Link to="https://www.instagram.com/petersfoundationin/" target="_blank">Instagram</Link>
          <Link to="https://www.facebook.com/people/Peters-Foundation/61567172879940/" target="_blank">Facebook</Link>
          <Link to="https://www.youtube.com/@PetersFoundation" target="_blank">YouTube</Link>
          <a href="mailto:petersfoundationin@gmail.com?subject=Inquiry&body=Hello, I have a question." target="_blank">Email Us</a>         
        </div>
      </div>

      {/* Social Media Section */}
      <div className="social-media">
        <div className="social-icons">
          <Link to="https://www.facebook.com/people/Peters-Foundation/61567172879940/" target="_blank" aria-label="Facebook">
            <i className="fab fa-facebook-f"></i>
          </Link>
          <Link to="https://www.instagram.com/petersfoundationin/" target="_blank" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </Link>
          <Link to="https://www.youtube.com/@PetersFoundation" target="_blank" aria-label="YouTube">
            <i className="fab fa-youtube"></i>
          </Link>
          <Link to="https://maps.app.goo.gl/5JgTXaZkkCbkLnG27" target="_blank" aria-label="Map">
            <i className="fas fa-map"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
