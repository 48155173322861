import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSection from '../Herosection';
import AboutUs from '../AboutUs';
import CardLinks from '../CardLinks';

function Home() {
  return (
    <>
      <HeroSection/>
      <CardLinks/>
      <AboutUs/>
    </>
  );
}

export default Home;